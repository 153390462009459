<script setup>
const { globals } = useSharedState()

const props = defineProps({
    product: {
        type: Object,
        default: () => ({}),
    },
})

const mainImage = props.product?.image?.[0]?.url ? props.product?.image?.[0] : globals.value.brands?.defaultImage?.[0]
</script>

<template>
    <MainLink
        class="group block"
        :to="product?.url"
    >
        <div class="relative mb-4 aspect-square overflow-hidden rounded-2xl bg-current">
            <div class="absolute left-1/2 top-0 size-[180%] -translate-x-1/2 translate-y-full bg-radial from-white from-10% via-white/90 via-25% to-white/0 to-70% mix-blend-overlay transition duration-650 group-hover:translate-y-[15%]" />
            <img
                v-if="mainImage?.url"
                class="backface-hidden absolute left-1/2 top-6 h-auto w-13/24 -translate-x-1/2 transition duration-650 group-hover:scale-110"
                :src="mainImage.url"
                :srcset="mainImage.srcset"
                :alt="mainImage.alt"
            >
        </div>
        <div
            class="text-xl font-medium leading-none lg:text-2xl lg:leading-none"
            v-html="product.title"
        />
    </MainLink>
</template>
